import React from "react"

const YoutubeIcon = () => (
  <svg
    width="35"
    height="24"
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.6235 3.70587C33.4244 2.9927 33.0448 2.34294 32.5212 1.81938C31.9977 1.29582 31.3479 0.916178 30.6347 0.717143C27.9581 2.05614e-05 17.1855 2.0305e-05 17.1855 2.0305e-05C17.1855 2.0305e-05 6.4401 -0.0142932 3.73622 0.717143C3.02305 0.916178 2.37328 1.29582 1.84973 1.81938C1.32617 2.34294 0.946525 2.9927 0.747491 3.70587C0.240095 6.44626 -0.0100533 9.22809 0.000309168 12.015C-0.00809429 14.7915 0.242046 17.5627 0.747491 20.2927C0.946525 21.0059 1.32617 21.6557 1.84973 22.1792C2.37328 22.7028 3.02305 23.0824 3.73622 23.2814C6.41004 24 17.1855 24 17.1855 24C17.1855 24 27.9294 24 30.6347 23.2814C31.3479 23.0824 31.9977 22.7028 32.5212 22.1792C33.0448 21.6557 33.4244 21.0059 33.6235 20.2927C34.1184 17.5617 34.358 14.7905 34.3392 12.015C34.3599 9.22907 34.1203 6.44726 33.6235 3.70587V3.70587ZM13.7473 17.1551V6.8592L22.7135 12.015L13.7473 17.1551Z"
      fill="white"
    />
  </svg>
)

export default YoutubeIcon
