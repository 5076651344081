import React from "react"
import styled from "@emotion/styled"

const Container = styled.p`
  max-width: 1130px;
  padding: 0 1rem;
  margin: 1.5rem 0;
  text-align: center;
  font-size: 0.625em;
  color: #f5f5f5;
  @media (max-width: 424px) {
    padding: 0 1rem;
  }
  @media (max-width: 1024px) {
    text-align: center;
    max-width: 676px;
  }
`

const Disclaimer = () => (
  <Container>
    En los mercados financieros se generan rápidos movimientos que pueden
    conllevar a un elevado riesgo de pérdidas. Por esta razón, participar
    activamente en estos mercados con apalancamiento financiero, solo es
    aconsejable para aquellos participantes que puedan asumir dicho riesgo.
    Cualquier análisis o consejo sobre el mercado comunicado a través de 
    www.capitaria.com o de algún correo electrónico con este remitente, debe ser
    considerado como una opinión, y nunca como una garantía o recomendación de inversión. 
    En ningún caso y bajo ningún concepto, Capitaria se hace responsable de las 
    pérdidas incurridas por nuestros clientes. Todo cliente potencial de 
    Capitaria debe tener en cuenta que su capital puede verse tanto incrementado como
    disminuido, no debiendo participar en este mercado sin antes considerar si
    esta manera de operar es la adecuada para él, en función de sus condiciones
    económicas. Los clientes potenciales deben también tener en cuenta que no se
    pueden garantizar los resultados de las operaciones, y que los resultados
    obtenidos en el pasado no garantizan resultados futuros. El trading implica
    un alto riesgo y un cliente puede perder una cantidad sustancial de dinero,
    no importando qué método utilice. Capitaria ofrece instrumentos transados a
    través de CFD's, que no están regulados por alguna entidad local. Capitaria 
    no es asesor de inversiones.
  </Container>
)

export default Disclaimer
