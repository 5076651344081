import React from "react"
import styled from "@emotion/styled"

import TwitterIcon from "./images/twitter-icon"
import FacebookIcon from "./images/facebook-icon"
import InstagramIcon from "./images/instagram-icon"
import LinkedinIcon from "./images/linkedin-icon"
import YoutubeIcon from "./images/youtube-icon"

const SocialNetworkWrapper = styled.div`
  text-align: center;
  padding-top: 5rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const IconList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
`

const LinkIcon = styled.a`
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
`

const SocialNetwork = () => (
  <SocialNetworkWrapper>
    <Container>
      <IconList>
        <li style={{ padding: "0 0.5rem 0 0" }}>
          <LinkIcon href="https://twitter.com/somoscapitaria" target="_blank">
            <TwitterIcon />
          </LinkIcon>
        </li>

        <li style={{ padding: "0 0.5rem" }}>
          <LinkIcon href="https://www.facebook.com/Capitaria/" target="_blank">
            <FacebookIcon />
          </LinkIcon>
        </li>

        <li style={{ padding: "0 0.5rem" }}>
          <LinkIcon
            href="https://www.instagram.com/somoscapitaria/"
            target="_blank"
          >
            <InstagramIcon />
          </LinkIcon>
        </li>

        <li style={{ padding: "0 0.5rem" }}>
          <LinkIcon
            href="https://www.linkedin.com/company/capitaria"
            target="_blank"
          >
            <LinkedinIcon />
          </LinkIcon>
        </li>

        <li style={{ padding: "0 0 0 0.5rem" }}>
          <LinkIcon href="https://www.youtube.com/c/Capitaria" target="_blank">
            <YoutubeIcon />
          </LinkIcon>
        </li>
      </IconList>
    </Container>
  </SocialNetworkWrapper>
)

export default SocialNetwork
