import React from "react"
import styled from "@emotion/styled"
import { Link } from "@reach/router"

import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"

const Pages = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 258px;
  @media (min-width: 600px) and (max-width: 768px) {
    flex-direction: row;
    min-width: 600px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: row;
    min-width: 676px;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    min-width: 847px;
  }
`

const PageContainer = styled.div`
  display: flex;
  max-width: 132px;
  flex-direction: column;
`

const PageTitle = styled.h2`
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
`

const PageLink = styled(Link)`
  color: #bdbdbd;
  font-size: 12px;
  line-height: 148.68%;
  text-decoration: none;
  margin-bottom: 1.75rem;
  & :hover {
    color: #ffffff;
  }
`

const ExternalLink = styled.a`
  color: #bdbdbd;
  font-size: 12px;
  line-height: 148.68%;
  text-decoration: none;
  margin-bottom: 1.75rem;
  & :hover {
    color: #ffffff;
  }
`

type Page = {
  text: string
  path: string
  external?: boolean
  blank?: boolean
  eventName: string
}

type PagesSectionProps = {
  title: string
  pages: Page[]
}

const PagesSection = ({ title, pages }: PagesSectionProps) => {
  const queryString = useQueryString().queryString
  const mcOrigen = useQueryString().parsedQueryString.mc_origen

  return (
    <PageContainer>
      <PageTitle>{title}</PageTitle>
      {pages.map((p, idx) => {
        const onClick = () => {
          trackEvent(`footer:${p.eventName}`, {
            mc_origen: mcOrigen,
          })
        }

        if (p.external) {
          return (
            <ExternalLink
              key={`page-${idx}`}
              href={`${p.path}${queryString}`}
              target={p.blank === false ? "_self" : "_blank"}
              rel="noopener noreferrer"
              onClick={onClick}
            >
              {p.text}
            </ExternalLink>
          )
        } else {
          return (
            <PageLink
              key={`page-${idx}`}
              to={`${p.path}${queryString}`}
              onClick={onClick}
            >
              {p.text}
            </PageLink>
          )
        }
      })}
    </PageContainer>
  )
}

const capitariaPages: Page[] = [
  {
    text: "¿Por qué Capitaria?",
    path: "/por-que-capitaria",
    eventName: "porque-capitaria",
  },
  {
    text: "Acerca de Nosotros",
    path: "/acerca-de-nosotros",
    eventName: "acerca-de-nosotros",
  },
  {
    text: "Legal",
    path: "/legal",
    eventName: "legal",
  },
  {
    text: "Trabaja con Nosotros",
    path: "/trabaja-con-nosotros",
    eventName: "trabaja-con-nosotros",
  },
]

const tradersPages: Page[] = [
  {
    text: "Tipos de Cuentas",
    path: "/tipos-de-cuentas",
    eventName: "tipos-de-cuentas",
  },
  {
    text: "Mercados y Aspectos Operativos",
    path: "/aspectos-operativos",
    eventName: "aspectos-operativos",
    external: true,
    blank: false,
  },
  {
    text: "Calculadora de Operaciones",
    path: "https://www.somoscapitaria.com/",
    eventName: "calculadora-de-operaciones",
    external: true,
  },
  {
    text: "Depósitos",
    path: "/depositos",
    eventName: "depositos",
  },
]

const platformPages: Page[] = [
  {
    text: "Preguntas Frecuentes",
    path:
      "https://capitaria.zendesk.com/hc/es/categories/360003147732-Preguntas-Frecuentes",
    eventName: "faq",
    external: true,
  },
  {
    text: "MT5 Web (Sólo Chile)",
    path: "/mt5-capitaria.html",
    eventName: "mt5-capitaria",
    external: true,
  },
  {
    text: "MT5 Web (Resto del mundo)",
    path: "/mt5-ktgroup.html",
    eventName: "mt5-ktgroup",
    external: true,
  },
]

const morePages: Page[] = [
  {
    text: "Cursos",
    path: "https://www.capitariacursostrading.com",
    eventName: "dojo",
    external: true,
  },
  {
    text: "Blog",
    path: "https://blog.capitaria.com/",
    eventName: "blog",
    external: true,
  },
  {
    text: "Calendario Económico",
    path:
      "https://www.blog.capitaria.com/calendario-economico?utm_campaign=capitaria&utm_medium=referral&utm_content=calendario-economico&utm_source=capitaria",
    eventName: "calendario-economico",
    external: true,
  }
]

const PagesLinks = () => (
  <Pages>
    <PagesSection title="Capitaria" pages={capitariaPages} />
    <PagesSection title="Traders" pages={tradersPages} />
    <PagesSection title="Plataforma" pages={platformPages} />
    <PagesSection title="Más" pages={morePages} />
  </Pages>
)

export default PagesLinks
