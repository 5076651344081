/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  min-width: 818px;
  padding-top: 3rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    min-width: auto;
  }
`

const Column = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  min-width: 239px;
  cursor: pointer;
`

const Country = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
`

const Field = styled.span`
  display: block;
  color: #6a6a6a;
`

const middleOfficeStyle = css`
  border: 1px solid #6a6a6a;
  border-top: 0;
  border-bottom: 0;
  padding: 0 1rem;
  @media (max-width: 1024px) {
    border: 1px solid #6a6a6a;
    border-left: 0;
    border-right: 0;
    padding-bottom: 20px;
  }
`

const telephoneStyle = css`
  @media (max-width: 1024px) {
    text-decoration: none;
    color: white;
    > a {
      text-decoration: none;
      color: white;
    }
  }
`

type Office = {
  street: string
  city: string
  region: string
  phone: string
  url: string
}

const offices: Office[] = [
  {
    street: "Av. Vitacura 3565, Oficina 101",
    city: "Santiago",
    region: "Vitacura, Santiago, Chile",
    phone: "+562 25926600",
    url: "https://maps.app.goo.gl/Uc71RBbQ7mw8nkdUA",
  },
  {
    street: "Víctor Andrés Belaúnde 147",
    city: "Lima",
    region: "Torre Real 2, San Isidro",
    phone: "+519 4473 7651",
    url: "https://maps.app.goo.gl/GuZ8jbnGvxtxvxVi6",
  },
  {
    street: "Dr. Luis Alberto de Herrera 1248 Torre 3 piso 12",
    city: "Montevideo",
    region: "Montevideo Uruguay",
    phone: "+598 2623 6583",
    url: "https://maps.app.goo.gl/d9dJ69eRjXkM1Nt3A",
  },
]

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url: string): (() => void) => () => openInNewTab(url)

const Offices = () => (
  <Container>
    {offices.map((o, idx) => (
      <Column
        key={`office-${idx}`}
        css={idx === 1 ? middleOfficeStyle : null}
      >
        <Country>{o.city}</Country>
        <Field onClick={onClickUrl(o.url)}>{o.street}</Field>
        <Field onClick={onClickUrl(o.url)}>{o.region}</Field>
        <Field css={telephoneStyle} onClick={onClickUrl(`tel:${o.phone}`)}>{o.phone}</Field>
      </Column>
    ))}
  </Container>
)

export default Offices
