import React from "react"
import styled from "@emotion/styled"

const CapitariaLogo = styled.svg`
  width: 137.32px;
  height: 30px;
  @media (max-width: 424px) {
    width: 104px;
    height: 22.72px;
  }
`

const CapitariaLogoWhite = () => (
  <CapitariaLogo
    width="165"
    height="36"
    viewBox="0 0 165 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M113.466 0.291016L123.168 21.6395L116.007 35.9998H106.531L113.678 21.6395L104.003 0.291016H113.466Z"
        fill="#62A947"
      />
      <path
        d="M2.27655 30.6397H0.29126V29.6206H5.37361V30.6397H3.38832V35.8942H2.27655V30.6397Z"
        fill="white"
      />
      <path
        d="M7.43823 29.6206H10.2309C11.025 29.6206 11.6338 29.8588 12.0441 30.2427C12.375 30.5868 12.5603 31.05 12.5603 31.5927V31.6059C12.5603 32.6383 11.9382 33.2603 11.0647 33.4986L12.7588 35.8677H11.4618L9.92647 33.6839H8.53676V35.8677H7.43823V29.6206ZM10.1515 32.7309C10.9456 32.7309 11.4353 32.3206 11.4353 31.6853V31.6721C11.4353 30.9971 10.9456 30.6397 10.1382 30.6397H8.53676V32.7441H10.1515V32.7309Z"
        fill="white"
      />
      <path
        d="M17.0337 29.5811H18.0528L20.819 35.8943H19.6543L19.019 34.3855H16.0543L15.4058 35.8943H14.2808L17.0337 29.5811ZM18.6087 33.4061L17.5234 30.8913L16.4514 33.4061H18.6087Z"
        fill="white"
      />
      <path
        d="M22.7913 29.6206H25.1339C27.106 29.6206 28.4692 30.9706 28.4692 32.7441V32.7574C28.4692 34.5177 27.106 35.8942 25.1339 35.8942H22.7913V29.6206ZM25.1339 34.8883C26.4574 34.8883 27.3177 34.0015 27.3177 32.7706V32.7574C27.3177 31.5265 26.4574 30.6265 25.1339 30.6265H23.903V34.8883H25.1339Z"
        fill="white"
      />
      <path
        d="M30.6926 29.6206H35.3515V30.6133H31.8044V32.2412H34.9412V33.2206H31.8044V34.9147H35.3912V35.8942H30.6926V29.6206Z"
        fill="white"
      />
      <path
        d="M37.6016 29.6216H40.3942C41.1883 29.6216 41.7972 29.8598 42.2074 30.2436C42.5383 30.5878 42.7236 31.051 42.7236 31.5936V31.6069C42.7236 32.6392 42.1016 33.2613 41.228 33.4995L42.9221 35.8687H41.6251L40.0898 33.6848H38.7133V35.8687H37.6148V29.6216H37.6016ZM40.3148 32.7319C41.1089 32.7319 41.5986 32.3216 41.5986 31.6863V31.6731C41.5986 30.9981 41.1089 30.6407 40.3016 30.6407H38.7001V32.7451H40.3148V32.7319Z"
        fill="white"
      />
      <path
        d="M44.55 34.9808L45.2118 34.1867C45.8074 34.7028 46.4162 35.0073 47.1971 35.0073C47.8721 35.0073 48.3088 34.6896 48.3088 34.2131V34.1999C48.3088 33.7499 48.0574 33.5117 46.8927 33.2337C45.5559 32.9161 44.8015 32.519 44.8015 31.3543V31.3411C44.8015 30.269 45.7015 29.5278 46.9456 29.5278C47.8588 29.5278 48.5868 29.8058 49.2221 30.322L48.6265 31.1558C48.0574 30.7322 47.5015 30.5072 46.9191 30.5072C46.2706 30.5072 45.9 30.8381 45.9 31.2484V31.2617C45.9 31.7514 46.1912 31.9631 47.3824 32.2411C48.7059 32.5587 49.3941 33.0352 49.3941 34.0808V34.094C49.3941 35.272 48.4677 35.9734 47.1574 35.9734C46.2177 35.9867 45.3045 35.6558 44.55 34.9808Z"
        fill="white"
      />
      <path
        d="M54.9133 32.7705V32.7573C54.9133 30.997 56.2766 29.5146 58.2089 29.5146C60.1412 29.5146 61.478 30.9705 61.478 32.7441V32.7573C61.478 34.5176 60.1148 35.9999 58.1824 35.9999C56.2633 35.9999 54.9133 34.5441 54.9133 32.7705ZM60.3133 32.7705V32.7573C60.3133 31.5396 59.4265 30.5205 58.1824 30.5205C56.9383 30.5205 56.0648 31.5132 56.0648 32.7308V32.7441C56.0648 33.9617 56.9516 34.9808 58.1957 34.9808C59.453 34.9941 60.3133 34.0014 60.3133 32.7705Z"
        fill="white"
      />
      <path
        d="M63.7146 29.6206H64.7337L68.0955 33.9618V29.6206H69.1808V35.8942H68.2543L64.7999 31.4338V35.8942H63.7146V29.6206Z"
        fill="white"
      />
      <path
        d="M71.6824 29.6206H72.7941V34.8883H76.0897V35.8942H71.6824V29.6206Z"
        fill="white"
      />
      <path
        d="M78.8162 33.4191L76.3147 29.6206H77.625L79.3721 32.4L81.1456 29.6206H82.4162L79.9279 33.3927V35.8942H78.8162V33.4191Z"
        fill="white"
      />
      <path
        d="M16.9412 16.4912C16.1338 17.1926 15.3 17.775 14.4794 18.2118C13.4868 18.7412 12.2824 19.0191 10.9059 19.0191C9.82059 19.0191 8.81471 18.8074 7.88823 18.3838C6.975 17.9603 6.16765 17.3779 5.49265 16.6368C4.81765 15.9088 4.30147 15.0353 3.93088 14.0559C3.56029 13.0765 3.375 12.0309 3.375 10.9324V10.8662C3.375 9.76765 3.56029 8.72206 3.93088 7.75588C4.30147 6.78971 4.81765 5.92941 5.49265 5.20147C6.16765 4.47353 6.96176 3.89118 7.88823 3.46765C8.81471 3.04412 9.82059 2.83235 10.9059 2.83235C12.2029 2.83235 13.3676 3.09706 14.3868 3.61323C15.2074 4.03676 16.0015 4.56618 16.7691 5.21471L16.9809 5.4L19.0059 3.07059L18.7941 2.88529C18.4235 2.55441 18.0265 2.23676 17.6294 1.94559C17.0735 1.54853 16.4515 1.19118 15.7897 0.913235C15.1279 0.622059 14.4 0.397059 13.6059 0.238235C12.7985 0.0794117 11.8985 0 10.9324 0C9.31765 0 7.83529 0.291176 6.525 0.860294C5.20147 1.42941 4.03676 2.22353 3.07059 3.21618C2.10441 4.20882 1.33676 5.37353 0.807353 6.68382C0.264706 7.99412 0 9.42353 0 10.9324V10.9985C0 12.5074 0.277941 13.9368 0.820588 15.2603C1.36324 16.5838 2.13088 17.7485 3.08382 18.7147C4.03676 19.6809 5.18823 20.4618 6.48529 21.0176C7.78235 21.5735 9.225 21.8647 10.7735 21.8647C11.7662 21.8647 12.6794 21.7721 13.4868 21.6C14.3074 21.4279 15.0485 21.1765 15.7235 20.8456C16.3985 20.5147 17.0471 20.1176 17.6426 19.6676C18.0926 19.3235 18.5294 18.9397 18.9397 18.5294L19.1382 18.3309L17.1265 16.3059L16.9412 16.4912Z"
        fill="white"
      />
      <path
        d="M58.0897 2.27644C57.4015 1.66761 56.5677 1.19114 55.5883 0.873495C54.5956 0.542613 53.4706 0.383789 52.253 0.383789H43.9941V21.6529H47.1177V14.4926H51.7897C52.9544 14.4926 54.0662 14.3338 55.0986 14.0294C56.1177 13.725 57.0044 13.2617 57.7588 12.6661C58.5133 12.0706 59.1088 11.3294 59.5324 10.4691C59.9691 9.59555 60.1809 8.5632 60.1809 7.39849V7.33232C60.1809 6.28673 59.9956 5.32055 59.6383 4.47349C59.2809 3.62644 58.7647 2.88526 58.0897 2.27644ZM47.1309 3.1632H51.9221C53.4309 3.1632 54.6486 3.52055 55.5618 4.22202C56.5015 4.93673 56.978 6.00879 56.978 7.41173V7.47791C56.978 8.72202 56.5147 9.75438 55.6015 10.5353C54.7015 11.3161 53.4706 11.7132 51.9353 11.7132H47.1441V3.1632H47.1309Z"
        fill="white"
      />
      <path
        d="M67.6192 0.370605H64.4824V21.6397H67.6192V0.370605Z"
        fill="white"
      />
      <path
        d="M89.0867 0.370605H72.0793V3.2559H78.9882V21.6397H82.1779V3.2559H89.0867V0.370605Z"
        fill="white"
      />
      <path
        d="M139.487 0.370605H136.363V21.6397H139.487V0.370605Z"
        fill="white"
      />
      <path
        d="M99.9661 0.370605H97.0543L88.2661 21.6397H91.6808L98.4441 5.09561L105.234 21.6397H108.741L100.032 0.5559L99.9661 0.370605Z"
        fill="white"
      />
      <path
        d="M32.5852 0.370605H29.6735L20.8853 21.6397H24.3L31.0632 5.09561L37.8661 21.6397H41.3602L32.6514 0.5559L32.5852 0.370605Z"
        fill="white"
      />
      <path
        d="M156.071 0.5559L156.004 0.370605H153.079L144.304 21.6397H147.719L154.482 5.09561L161.272 21.6397H164.779L156.071 0.5559Z"
        fill="white"
      />
      <path
        d="M130.354 2.43531C129.693 1.80002 128.846 1.28384 127.84 0.926488C126.834 0.5559 125.643 0.370605 124.319 0.370605H117.874L119.171 3.15002H123.975C125.484 3.15002 126.675 3.4809 127.522 4.14266C128.396 4.81766 128.846 5.82355 128.846 7.10737V7.17355C128.846 8.39119 128.396 9.38384 127.496 10.0985C126.794 10.6809 125.868 11.025 124.782 11.1177L123.366 11.1574H122.837L127.76 21.6397H131.387L127.35 13.2353C128.051 13.0235 128.634 12.6927 129.137 12.3883C129.732 12.0309 130.249 11.5809 130.685 11.0647C131.122 10.5485 131.453 9.93972 131.704 9.26472C131.943 8.57649 132.075 7.80884 132.075 6.96178V6.8956C132.075 5.99561 131.929 5.14855 131.638 4.40737C131.334 3.67943 130.91 3.01766 130.354 2.43531Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="164.779" height="36" fill="white" />
      </clipPath>
    </defs>
  </CapitariaLogo>
)

export default CapitariaLogoWhite
