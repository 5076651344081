import React from "react"
import styled from "@emotion/styled"

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0 20px;
  width: 240px;
`

const FinteChileLogo = styled.img`
  width: 110px;
  height: 34px;
`
const DesafioLogo = styled.img`
  width: 50px;
  height: 32px;
`

const GreatPlaceToWork = styled.img`
  width: 33px;
  height: 56px;
`

const Partners = () => (
  <Content>
    <FinteChileLogo src="/fintechile-logo.png" alt="FinteChile" />
    <DesafioLogo src="/desafio-10x-logo.png" alt="Desafio 10x" />
    <GreatPlaceToWork
      src="/great-place-to-work-small.png"
      alt="Great Place To Work"
    />
  </Content>
)

export default Partners
