import React from "react"

const TwitterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM18.036 10.0979C18.036 13.9199 15.126 18.3299 9.804 18.3299C8.172 18.3299 6.648 17.8499 5.382 17.0219C5.61 17.0519 5.838 17.0639 6.072 17.0639C7.428 17.0639 8.676 16.6019 9.666 15.8279C8.4 15.8039 7.332 14.9699 6.966 13.8179C7.146 13.8539 7.326 13.8719 7.512 13.8719C7.776 13.8719 8.034 13.8359 8.274 13.7699C6.948 13.4999 5.952 12.3359 5.952 10.9319V10.8959C6.342 11.1119 6.786 11.2439 7.26 11.2559C6.48 10.7399 5.97 9.85193 5.97 8.84993C5.97 8.32193 6.114 7.82393 6.36 7.39793C7.788 9.14993 9.918 10.3019 12.324 10.4219C12.27 10.2119 12.246 9.98993 12.246 9.76193C12.246 8.16593 13.542 6.86993 15.138 6.86993C15.972 6.86993 16.722 7.21793 17.25 7.78193C17.91 7.64993 18.528 7.40993 19.086 7.07993C18.87 7.75793 18.414 8.32193 17.814 8.68193C18.402 8.60993 18.96 8.45393 19.476 8.22593C19.086 8.80793 18.594 9.31793 18.03 9.72593C18.036 9.84593 18.036 9.97193 18.036 10.0979Z"
      fill="white"
    />
  </svg>
)

export default TwitterIcon
